var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "roadOut bgFFF" }, [
    _c(
      "div",
      { staticClass: "breadcrumb" },
      [
        _c(
          "el-button",
          {
            staticClass: "right",
            staticStyle: { width: "88px", height: "36px" },
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          },
          [_vm._v(" 返回 ")]
        ),
      ],
      1
    ),
    _vm.equipmentList.length !== 0
      ? _c(
          "div",
          _vm._l(_vm.equipmentList, function (item) {
            return _c(
              "div",
              { key: item.index },
              [_c("equipment-list", { attrs: { dataSource: item } })],
              1
            )
          }),
          0
        )
      : _vm._e(),
    _vm.equipmentList.length === 0
      ? _c("div", { staticStyle: { "text-align": "center" } }, [
          _vm._v(" 暂无设备 "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }