<!--
 * @Author: your name
 * @Date: 2021-01-29 11:27:59
 * @LastEditTime: 2021-03-05 10:57:04
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \aiparkcity-acb\src\views\equipmentManage\roadOutEquipment\roadOutEquipmentDetail.vue
-->
<!-- 路外设备详情 -->
<template>
  <div class="roadOut bgFFF">
    <div class="breadcrumb">
      <el-button
        class="right"
        type="primary"
        style="width: 88px; height: 36px;"
        @click="$router.go(-1)"
      >
        返回
      </el-button>
    </div>
    <div v-if="equipmentList.length !== 0">
      <div v-for="item in equipmentList" :key="item.index">
        <equipment-list :dataSource="item"></equipment-list>
      </div>
    </div>
    <div style="text-align: center" v-if="equipmentList.length === 0">
      暂无设备
    </div>
  </div>
</template>

<script>
import equipmentList from "./equipmentList";
export default {
  data() {
    return {
      parkInfo: {},
      equipmentList: {}
    };
  },
  components: {
    equipmentList
  },
  methods: {
    getDetails(parkId) {
      // GET /thirdEquipment/{parkId}
      let opt = {
        method: "get",
        url: "/acb/2.0/thirdEquipment/" + parkId,
        data: {},
        success: res => {
          if (res.state == 0) {
            this.equipmentList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
          }
        }
      };
      this.$request(opt);
    }
  },
  mounted() {
    this.parkInfo = this.$route.query;
    this.getDetails(this.parkInfo.parkId);
  }
};
</script>
<style lang="stylus" scoped>
.roadOut
  padding 0px 15px 15px 15px
  .breadcrumb
    height: 35px;
    margin-bottom 15px
</style>
