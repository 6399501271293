<!-- 设备列表组价 -->
<template>
  <div class="main_box">
    <div class="title">
      <span>{{ dataSource.thirdEquipmentType | equipmentTypeName }}</span>
    </div>
    <div class="equipmentLists">
      <div class="equipmentList" v-for="(item, index) in dataSource.thirdEquipmentList" :key="index">
        <div class="equipmentDetail">
          <p class="name">{{ item.equipmentName }}</p>
          <p class="status" :class="item.equipmentStatus ? 'green' : 'red'">
            {{ item.equipmentStatus | equipmentStatus }}
          </p>
          <p class="time">{{ item.updatedTime }}</p>
        </div>
        <div>
          <img width="48px" height="50px" :src="dataSource.thirdEquipmentType | equipmentImage" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    dataSource: {
      type: Object
    }
  },
  filters: {
    equipmentTypeName(type) {
      let equipmentTypeList = {
        1: "MS",
        2: "闸机",
        3: "票箱",
        4: "LED屏",
        5: "认证缴费机",
        6: "识别一体机",
        7: "诱导屏",
        8: "自助缴费机"
      };
      return equipmentTypeList[type];
    },
    equipmentStatus(status) {
      let equipmentStatusList = {
        0: "离线",
        1: "在线"
      };
      return equipmentStatusList[status];
    },
    equipmentImage(imgId) {
      return require("./equipment-" + imgId + ".png");
    }
  },
  mounted() {}
};
</script>
<style lang="stylus" scoped>
.main_box
  // padding 15px
  .title
    height 40px
    line-height 40px
    background-color #F4F7FA
    padding 0px 16px
    span
      padding 0px 20px
      border-left 3px solid #0099FA
      font-size 16px
  .equipmentLists
    padding 20px
    display flex
    justify-content left
    background-color #E3E3E3
    margin-bottom 20px
    border-radius 0 0 4px 4px
    .equipmentList
      display flex
      justify-content space-between
      align-items center
      padding 15px 20px
      background-color #ffffff
      border-radius 12px
      margin-right 10px
      box-shadow:0px 0px 16px 2px rgba(119,154,241,0.2),0px 0px 16px 0px rgba(119,154,241,0.04);
      .equipmentDetail
        margin-right 10px
        .name
          color #333333
          font-size 16px
        .status
          font-size 12px
        .time
          color #666666
          font-size 12px
        .green
          color #389E0D
        .red
          color #ff0000
        p
          line-height 26px
</style>
